import AddCircleIcon from '@mui/icons-material/AddCircle';
import BlogToolBar from '../components/BlogToolBar';
import { Box,useTheme,Container,useMediaQuery,styled,Typography,Grid,CardContent,Card,Button } from '@mui/material';
import BottomSpacer from 'uicomponents/BottomSpacer';
import React,{useEffect} from 'react'
import ShareButtons from '../components/ShareButtons';
import { Small } from 'uicomponents/Typography';



export default function CompoundInterest() {

    const theme = useTheme();

  
    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const downMd = useMediaQuery(theme => theme.breakpoints.down("md"));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up("md"))
    
    const Wrapper = styled(Box)(() => ({
      backgroundImage: "url('/static/landing-page/blogpagebackground.svg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "repeat",
      position:'absolute',
      top:0,
      right:0,
      width:'100%',
      height:'100%'
    }));

    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])

    function determineMaxWidth(){
      if(isSmall){
        return 500
      }
      else if(downMd){
        return 500
      }
      else if(isLarge){
        return 800
      }
      else {
        return 800
      }
      
    }

  

 
  return (
<div>
    <Wrapper>
    <BlogToolBar />

    <Grid container justifyContent='center' display='flex' flexDirection='column' alignContent='center' alignItems='center'> 
    <Grid item>
    <Typography variant='h3' style={{textAlign:"center",fontWeight:'bold',color:'#1A77CD',marginTop:100}}>What is Compound Interest?</Typography>
    </Grid>
  
      <Grid item mt={2} style={{textAlign:'center',maxWidth:determineMaxWidth()}}>
          <Small style={{textAlign:'center'}} justifyContent='center'  mb={3} fontSize={16} fontWeight={600} color="text.disabled">
          In simple terms, compound interest is the interest you earn on both the money you initially save (the principal) and the interest that accumulates over time. Unlike simple interest, which is calculated only on the principal amount, compound interest grows on the total balance—creating a snowball effect that accelerates growth.
          </Small>
      </Grid>
    </Grid>

<Container style={{maxWidth:determineMaxWidth()}}>
    <div>



<h2>How Compound Interest Works</h2>
<p>Imagine you invest $1,000 at a 5% annual interest rate, compounded annually:</p>
<ul>
    <li>At the end of the first year, you’d have $1,050 ($1,000 + $50 interest).</li>
    <li>At the end of the second year, you’d earn interest on $1,050, bringing your total to $1,102.50.</li>
    <li>By the end of 10 years, your initial $1,000 would grow to $1,628.89—without you adding a single extra dollar.</li>
</ul>

<p>The magic of compound interest lies in its ability to grow your money faster as time goes on. The earlier you start saving, the more dramatic the impact of compounding becomes.</p>

<h2>Why Is Compound Interest So Important?</h2>

<h3>1. Accelerates Wealth Building</h3>
<p>Compound interest allows even small, consistent contributions to grow into significant sums over time. By reinvesting your earnings, your savings grow faster without requiring additional effort or input.</p>

<h3>2. Rewards Early Saving</h3>
<p>Time is the most critical factor when it comes to leveraging compound interest. The longer your money stays invested, the more opportunities it has to grow.</p>
<p>For example, if two people invest $5,000 annually at a 7% interest rate, but one starts at age 25 and the other at age 35, the first investor will end up with nearly twice as much money by retirement—even if they both stop contributing at 65.</p>

<h3>3. Combats Inflation</h3>
<p>Inflation erodes the purchasing power of money over time. Compound interest not only helps your savings keep pace with inflation but also ensures your money grows beyond its original value.  Keeping or outpacing inflation is essential for your budget and long-term goals.</p>

<h3>4. Encourages Consistent Savings Habits</h3>
<p>Knowing how powerful compound interest can be often motivates people to save regularly, helping them develop disciplined financial habits.</p>

<h2>Practical Steps to Harness the Power of Compound Interest</h2>

<h3>1. Start Early</h3>
<p>The sooner you start saving, the more time your money has to grow. Even small contributions made early can have a substantial impact over decades.</p>

<h3>2. Invest Wisely</h3>
<p>Choose investments that offer competitive interest rates. While higher rates come with higher risks, a well-diversified portfolio can balance growth and security.</p>

<h3>3. Reinvest Earnings</h3>
<p>Avoid withdrawing interest or dividends from your savings or investments. Reinvesting these earnings maximizes the effect of compounding.  In tough budget months when you can't save additional money, at least you'll have additional earnings to save!</p>

<h3>4. Save Consistently</h3>
<p>Make regular contributions to your savings or investment accounts. Automated savings plans can help ensure consistency.</p>

<h3>5. Be Patient</h3>
<p>Compound interest works best over long periods. Resist the urge to dip into your savings early and let time do the heavy lifting.</p>


<h2>Conclusion</h2>
<p>Compound interest is the secret weapon for anyone looking to grow their savings and secure their financial future. By starting early, saving consistently, and letting your investments compound over time, you can turn modest contributions into substantial wealth. Whether you’re saving for retirement, a home, or your child’s education, understanding and leveraging compound interest is essential to achieving your financial goals. Start today and let the power of compounding work its magic!</p>
      <div style={{marginTop:25,fontWeight:500,fontStyle:'italic'}}>
            -Patrick Lysaght, Founder
            <div style={{marginTop:10}}>
            <ShareButtons url='https://www.budgeteven.com/learn/compoundinterest' title='Compound Interest' />
            </div>
          
      </div>
        


                    </div>

         
        
    </Container>
  
    <BottomSpacer />
</Wrapper>

    </div>
  

 
   
  )
}