import BlogToolBar from '../components/BlogToolBar';
import { Box,Container,useMediaQuery,styled,Typography,Grid} from '@mui/material';
import BottomSpacer from 'uicomponents/BottomSpacer';
import React,{useEffect} from 'react'
import ShareButtons from '../components/ShareButtons';
import { Small } from 'uicomponents/Typography';


export default function ShouldYouUseCreditCards() {

  
    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const downMd = useMediaQuery(theme => theme.breakpoints.down("md"));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up("md"))
    
    const Wrapper = styled(Box)(() => ({
      backgroundImage: "url('/static/landing-page/blogpagebackground.svg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "repeat",
      position:'absolute',
      top:0,
      right:0,
      width:'100%',
      height:'100%'
    }));

    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])

    function determineMaxWidth(){
      if(isSmall){
        return 500
      }
      else if(downMd){
        return 500
      }
      else if(isLarge){
        return 800
      }
      else {
        return 800
      }
      
    }


  return (


<div>
    <Wrapper>
    <BlogToolBar />

    <Grid container justifyContent='center' display='flex' flexDirection='column' alignContent='center' alignItems='center'> 
    <Grid item>
    <Typography variant='h3' style={{textAlign:"center",fontWeight:'bold',color:'#1A77CD',marginTop:100}}>Budgeting With Credit Cards</Typography>
    </Grid>
  
      <Grid item mt={2} style={{textAlign:'center'}}>
          <Small style={{textAlign:'center'}} justifyContent='center'  mb={3} fontSize={16} fontWeight={600} color="text.disabled">
            Let's talk about credit cards.  
          </Small>
      </Grid>


    </Grid>

<Container style={{maxWidth:determineMaxWidth()}}>
        <div style={{fontWeight:500,marginTop:25}}>
            Perhaps this is obvious to some, but for the new budgeters out there have no fear...this is a simple one.

        </div>


        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
           Probably not what you expected to hear am I right?  Well, contrary to Dave Ramsey's opinion, I suggest utilizing credit cards as long as you pay off the balance every month. 
            Do not use credit cards for purchases you will not be able to payoff the following month when the bill comes due.  The interest rates are way too high and carrying large balances can negatively impact your credit score.
        </div> 



        <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontStyle:'italic',fontSize:18,color:'#1A77CD'}}>Points and Opportunity Cost</span>

        </div>

        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
            If you utilize credit cards appropriately, the rewards offered by companies like Capital One can end up saving you money compared to making cash purchases.
       
        </div> 

        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
      
              For a simple example, let's say two people by the exact same $10 lunch every day from Subway.  Person A pays cash every day and Person B uses a credit card that 
              offers 2% back in rewards.  So, every time Person B buys a sandwich, the cost is technically $9.80 since 2% of $10 is 20 cents.  So, would you prefer to pay $10 or $9.80?
        </div> 


        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
            Over time, rewards start to accumulate and can be redeemed thereby saving you money over the long time.
        </div> 

 
        <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontStyle:'italic',fontSize:18,color:'#1A77CD'}}>Pay it off every month</span>

        </div>

        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
      
        Credit card interest is the cost you pay for borrowing money if you don’t pay your balance in full each month.  Credit card interest is typically an extremely high amount.  At the time of this wrigin, the average 
          annual percentage rate (APR) is around 24%.  
 
     </div> 


      <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontStyle:'italic',fontSize:18,color:'#1A77CD'}}>How credit card interest works (APR)</span>
        </div>

      <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
  
      Credit card interest is usually calculated using an annual percentage rate (APR), which is divided by 12 to find the monthly interest rate. For example, if your APR is 24%, your monthly interest rate is 2.0% (24% ÷ 12).
       If you have a $1,000 balance and only make the minimum payment, 2.0% of $1,000 is $20, so $20 is added to your balance. This means your new balance is $1,020, and the next month's interest will be calculated on this higher amount.
        Over time, unpaid balances can grow quickly due to this compounding which is why it's so important to pay off your balance every month.  
    </div> 


    <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontStyle:'italic',fontSize:18,color:'#1A77CD'}}>But what if I can't pay off my balance?</span>
        </div>

      <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
      I get it, life happens.  If you can't pay off your credit card balance, it's important to take proactive steps to avoid letting the debt spiral. 
      Start by paying as much as you can above the minimum payment—every extra dollar reduces the amount on which interest is calculated. 
      Contact your credit card company to see if they offer hardship programs, which may lower your interest rate or monthly payments temporarily. 
      Consider creating a strict budget to free up funds for your payments or explore options like balance transfer cards with lower introductory rates or personal loans to consolidate debt at a lower interest rate. 
      Ignoring the balance can lead to growing interest and fees, making it harder to regain control. Acting quickly helps limit the long-term impact.
    </div> 
        
    <div style={{marginTop:25,fontWeight:500,fontStyle:'italic'}}>
            -Patrick Lysaght, Founder
            <div style={{marginTop:10}}>
            <ShareButtons url='https://www.budgeteven.com/learn/usingcreditcards' title='Using Credit Cards' />
            </div>
          
      </div>
    </Container>
  
    <BottomSpacer />
</Wrapper>

    </div>
  

 
   
  )
}