export const primary = {
  100: "#D3F5FE",
  200: "#A7E7FD",
  300: "#7AD2FA",
  400: "#59BCF5",
  500: "#2499EF",
  main: "#2499EF",
  light: "#D3F5FE",
  dark: "#1A77CD",
  red: "#FF6B93",
  purple: "#A798FF",
  yellow: "#FF9777",
  dialogBackground: '#1A77CD',
  green:'#619B8A',
  background: '#F3F4F9',
  fiverGrayText:'#545370',
  navy:'#545370',
  blackcoral: "#545E70",
  mainred:"#ff0000",
  greenSuccess:"#2CC5BD",
  turqoise:"#18c3c6"
};

export const secondary = {
  100: "#F9F9F9",
  200: "#ECEFF5",
  300: "#E5EAF2",
  // outline or border
  400: "#94A4C4",
  // text muted
  500: "#1d2438",
  // main text
  main: "#1d2438",
  // main text
  light: "#F9F9F9",
  red: "#FF6B93",
  purple: "#A798FF",
  yellow: "#FF9777",
  liabilityHeader: '#FF6B93',
  assetHeader: '#2CC5BD'
};

export const mainred = {
  main: "red"
};

export const error = {
  main: "#FD396D"
};
export const success = {
  main: "#2CC5BD"
};
export const warning = {
  main: "#ff0000",
 
};
export const info = {
  main: "#1A77CD"
};
export const text = {
  primary: secondary[500],
  secondary: secondary[400],
  disabled: secondary[300],
  diabledBold: secondary[900]
};



export const fiverr = {
  assetGreen:'',
  liabilityOrange:''
}


























