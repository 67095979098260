import { Ellipsis} from 'react-spinners-css'
import { Grid,useTheme } from '@mui/material'
import React from 'react'

export default function CenteredEllipsis() {
    const theme = useTheme();
  return (
    <Grid item style={{display:'flex',justifyContent:'center',alignSelf:'center'}}><Ellipsis color={theme.palette.primary.main} /></Grid>
  )
}
