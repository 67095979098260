import BlogToolBar from '../components/BlogToolBar';
import { Box,Container,useMediaQuery,styled,Typography,Grid} from '@mui/material';
import BottomSpacer from 'uicomponents/BottomSpacer';
import React,{useEffect} from 'react'
import ShareButtons from '../components/ShareButtons';
import { Small } from 'uicomponents/Typography';


export default function OpportunityCost() {

  
    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const downMd = useMediaQuery(theme => theme.breakpoints.down("md"));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up("md"))
    
    const Wrapper = styled(Box)(() => ({
      backgroundImage: "url('/static/landing-page/blogpagebackground.svg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "repeat",
      position:'absolute',
      top:0,
      right:0,
      width:'100%',
      height:'100%'
    }));

    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])

    function determineMaxWidth(){
      if(isSmall){
        return 500
      }
      else if(downMd){
        return 500
      }
      else if(isLarge){
        return 800
      }
      else {
        return 800
      }
      
    }


  return (


<div>
    <Wrapper>
    <BlogToolBar />

    <Grid container justifyContent='center' display='flex' flexDirection='column' alignContent='center' alignItems='center'> 
    <Grid item>
    <Typography variant='h3' style={{textAlign:"center",fontWeight:'bold',color:'#1A77CD',marginTop:100}}>What is Opportunity Cost?</Typography>
    </Grid>
  
 


    </Grid>

<Container style={{maxWidth:determineMaxWidth()}}>
        <div style={{fontWeight:500,marginTop:25}}>
        When it comes to personal finance, we often focus on the dollars we spend rather than the opportunities we give up. Enter the concept of opportunity cost – the idea that every choice comes with a trade-off. 
        Understanding opportunity cost can be a powerful way to rethink your spending habits and maximize the value of your hard-earned money.
        </div>


        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
        Opportunity cost refers to the value of the next best alternative that you forgo when you make a decision. For example, if you spend $5 on a coffee every day, you’re not just spending money; you’re giving up the chance to use that money elsewhere. 
        It could be invested, saved, or redirected toward a financial goal like paying off debt or building an emergency fund.
        </div> 



        <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>The $5 Coffee Example</span>

        </div>

        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
        Let’s break down the opportunity cost of a seemingly small expense: a daily $5 coffee.
            <ul>
                <li><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                    $5 per day:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                    At $5 per day, five days a week, you’re spending $25 weekly. Over four weeks, that adds up to $100 per month.</span></span>
                
                </li>
                <li style={{marginTop:10}}><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                    Annual Cost:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                   Over a year, that's $1,200.</span></span>
                
                </li>
                <li style={{marginTop:10}}><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                    Lifetime Cost:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                    Assuming you continue this habit for 30 years, you’ve spent $36,000 on coffee.</span></span>
                
                </li>
            </ul>
       
        </div> 

        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
      
        Now, imagine if you decided to brew coffee at home instead, at a cost of $0.50 per cup. You could save around $4.50 per day, or roughly $1,080 annually.
        </div> 




 
        <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontSize:18,color:'#1A77CD'}}>Investing the Savings</span>

        </div>

        <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
      
        Let’s take this a step further and calculate the potential growth if you invested that $1,080 annually. 
        Assuming an average annual return of 7% (a common benchmark for stock market investments), here’s how the savings could grow:
        <ul>
                <li><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                    10 Years:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                    $14,922</span></span>
                
                </li>
                <li style={{marginTop:10}}><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                    20 years:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                   $44,275</span></span>
                
                </li>
                <li style={{marginTop:10}}><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                    30 years:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                    $102,018</span></span>
                
                </li>
            </ul>
            Suddenly, that daily coffee run doesn’t seem so harmless!
     </div> 


      <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontStyle:'italic',fontSize:18,color:'#1A77CD'}}>Other Everday Examples for your Budget</span>
        </div>

      <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
  
      <ul>
                <li><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                   Dining Out vs. Cooking at Home:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                   Spending $50 on a dinner out might cost you not just the $50 but also the chance to buy a week’s worth of groceries instead.</span></span>
                
                </li>
                <li style={{marginTop:10}}><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                    Subscriptions You Don't Use:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                    A $15 monthly subscription may seem minor, but if it’s not adding value to your life, you’re wasting $180 annually – money that 
                    could go toward a weekend getaway or a new skill.</span></span>
                
                </li>
                <li style={{marginTop:10}}><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                   Impulse Purchases:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                   That $100 jacket on sale might save you money compared to its original price, but if you didn’t need it, you’ve given up the opportunity to 
                   put $100 into savings or pay off debt.</span></span>
                
                </li>
            </ul>
    </div> 


    <div style={{marginTop:25}}>
            <span style={{fontWeight:'bold',fontStyle:'italic',fontSize:18,color:'#1A77CD'}}>How to Apply Opportunity Cost Thinking</span>
        </div>

      <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
  
      <ul>
                <li><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                   Pause Before Purchasing:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                   Ask yourself, “What else could I do with this money?”</span></span>
                
                </li>
                <li style={{marginTop:10}}><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                    Track your spending :<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                    Use <a href='https://www.budgeteven.com'>Budget Even</a> to categorize expenses and identify areas where you can cut back.</span></span>
                
                </li>
                <li style={{marginTop:10}}><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                   Set Financial Goals:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                   Redirect money saved from unnecessary expenses into a high-yield savings account, retirement fund, or investment account.</span></span>
                
                </li>

                <li style={{marginTop:10}}><span style={{fontWeight:'bold',fontSize:18,color:'black'}}>
                   Think Long-Term:<span style={{fontWeight:500,fontSize:14,marginLeft:10}}>
                   Visualize how today’s small savings can grow exponentially over time.</span></span>
                
                </li>
            </ul>

          
    </div> 
    <div style={{marginTop:10,fontWeight:500,marginTop:25}}>
    Opportunity cost is a subtle yet powerful force in personal finance. By considering what you give up with every purchase, you can make more intentional decisions that align with your financial goals. Whether it’s skipping the $5 coffee or canceling an unused subscription, every dollar you save has the potential to work harder for you in the future. 
    Your financial well-being is built not just on the money you earn but on how wisely you choose to spend it.
    </div>


    <div style={{marginTop:25,fontWeight:500,fontStyle:'italic'}}>
            -Patrick Lysaght, Founder
            <div style={{marginTop:10}}>
            <ShareButtons url='https://www.budgeteven.com/learn/opportunitycost' title='Opportunity Cost' />
            </div>
          
      </div>
    </Container>
  
    <BottomSpacer />
</Wrapper>

    </div>
  

 
   
  )
}