import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, doc, getFirestore,getDocs, updateDoc,where,query, setDoc, getDoc, deleteDoc, } from "firebase/firestore";
import { uploadError } from "./FirebaseAnalyticsMethods";


//get rid of getAcconts since getFastFinAccounts does it
export async function getAccounts(finCustomerId){
try{
    const functions = getFunctions();
    const userUid = getAuth().currentUser.uid;
    const db = getFirestore();

    let retrieveBalances = httpsCallable(functions,'getFastFinAccounts');

    let finAccountsArray = await retrieveBalances({customerId: finCustomerId}).then(function(result) {
  
      return result.data.accounts;
    }).catch(function(error){
  
      return false;
    })
 


    let accountsRef = collection(db,'EuklesUsersData',userUid,'Accounts')
    let q = query(accountsRef,where("isCashFlowAccount","==",true))

    let cashFlowAccountId = await getDocs(q).then(querySnapshot => {
     let id = ''

      querySnapshot.forEach(doc => { 
     
        id = doc.id
      })

      return id
    }).catch(e => {
      return false
    })

   

    if(finAccountsArray === false || finAccountsArray.length === 0){
      //error

    }
    else {
      
      for(let i = 0; i < finAccountsArray.length; i++){
          if(finAccountsArray[i].id !== cashFlowAccountId){
              let myObject = {...finAccountsArray[i], isFinicity:true}
              let accountsDoc = doc(db,'EuklesUsersData',userUid,'Accounts',String(finAccountsArray[i].id))
              await updateDoc(accountsDoc,myObject)
             
          }
          else {
              let myObjectAlt = {...finAccountsArray[i], isFinicity:true, isCashFlowAccount:true}
              let accountsDocRef = doc(db,'EuklesUsersData',userUid,'Accounts',String(finAccountsArray[i].id))
              await updateDoc(accountsDocRef,myObjectAlt)
            
          }
       
    }
  }



}catch(e){
uploadError(e.message,"function is getAccounts, AccountMethods.js",)
}
}

export async function checkForLogo(institution){
  try{

    const uid = getAuth().currentUser.uid;
    const db  = getFirestore();
    let accountsRef = doc(db,'EuklesUsersData',uid,'Logos',String(institution))
    let logo = await getDoc(accountsRef).then(doc => {
      if(doc.exists){
        return doc.data().logo
      }
      else {
       
        return 'no_doc'
      }
    }).catch(e => {
      return false;
    })
 
    return logo

  }catch(e){
    uploadError(e.message,'AccountMethods.js, checkForLogo')
  }
}

export async function getInvestmentAccountsFromFireStore(){

  try{
    const db = getFirestore();  
    let accountsRef = collection(db,'EuklesUsersData',getAuth().currentUser.uid,'Accounts')
    const accounts =  await getDocs(accountsRef).then(querySnapshot => {
      let localArray = []
      querySnapshot.forEach((doc) => {
        
        if(doc.data().isFinicity){
  
         
  
          let accountNickname = ''
          if(typeof doc.data().accountNickname !== 'undefined'){
            accountNickname = doc.data().accountNickname
          }
          let accountNumberDisplay = ''
          if(typeof doc.data().accountNumberDisplay !== 'undefined'){
            accountNumberDisplay = doc.data().accountNumberDisplay
          }
          let aggregationAttemptDate = ''
          if(typeof doc.data().aggregationAttemptDate !== 'undefined'){
            aggregationAttemptDate = doc.data().aggregationAttemptDate
          }
  
          let aggregationStatusCode = ''
          if(typeof doc.data().aggregationStatusCode !== 'undefined'){
            aggregationStatusCode = doc.data().aggregationStatusCode
          }
  
          let aggregationSuccessDate = ''
          if(typeof doc.data().aggregationSuccessDate !== 'undefined'){
            aggregationSuccessDate = doc.data().aggregationSuccessDate
          }
  
          let balance = ''
          if(typeof doc.data().balance !== 'undefined'){
            balance = doc.data().balance
          }
  
          let balanceDate = ''
          if(typeof doc.data().balanceDate !== 'undefined'){
            balanceDate = doc.data().balanceDate
          }
          let createdDate = ''
          if(typeof doc.data().createdDate !== 'undefined'){
            createdDate = doc.data().createdDate
          }
          let currency = ''
          if(typeof doc.data().currency !== 'undefined'){
            currency = doc.data().currency
          }
          let detailCurrentBalance = ''
        
          let detailLastPaymentAmount = ''
          let detailLastPaymentDate = ''
          let detailPaymentDueDate = ''
          let detailPaymentMinAmount = ''
          let detailStatementCloseBalance = ''
  
          if(typeof doc.data().detail !== 'undefined'){
            
            if(typeof doc.data().detail.availableBalanceAmount !== 'undefined'){
              balance = doc.data().detail.availableBalanceAmount
            }
  
            if(typeof doc.data().detail.currentBalance !== 'undefined'){
              detailCurrentBalance = doc.data().detail.currentBalance
            }
  
            if(typeof doc.data().detail.lastPaymentAmount !== 'undefined'){
              detailLastPaymentAmount = doc.data().detail.lastPaymentAmount
            }
  
            if(typeof doc.data().detail.lastPaymentDate !== 'undefined'){
              detailLastPaymentDate = doc.data().detail.lastPaymentDate
            }
  
            if(typeof doc.data().detail.paymentDueDate !== 'undefined'){
              detailPaymentDueDate = doc.data().detail.paymentDueDate
            }
  
            if(typeof doc.data().detail.paymentMinAmount !== 'undefined'){
              detailPaymentMinAmount = doc.data().detail.paymentMinAmount
            }
  
            if(typeof doc.data().detail.statementCloseBalance !== 'undefined'){
              detailStatementCloseBalance = doc.data().detail.statementCloseBalance
            }
  
  
          }
  
  
  
          let displayPosition = ''
          if(typeof doc.data().displayPosition !== 'undefined'){
            displayPosition = doc.data().displayPosition
          }
          let accountId = ''
          if(typeof doc.data().accountId !== 'undefined'){
            accountId = doc.data().accountId
          }
          let institutionId =  ''
          if(typeof doc.data().institutionId !== 'undefined'){
            institutionId = doc.data().institutionId
          }
          let institutionLoginId = ''
          if(typeof doc.data().institutionLoginId !== 'undefined'){
            institutionLoginId = doc.data().institutionLoginId
          }
  
      
          let lastTransactionDate = ''
          if(typeof doc.data().lastTransactionDate !== 'undefined'){
            lastTransactionDate = doc.data().lastTransactionDate
          }
          let lastUpdatedDate = ''
          if(typeof doc.data().lastUpdatedDate !== 'undefined'){
            lastUpdatedDate = doc.data().lastUpdatedDate
          }
          let marketSegment = ''
          if(typeof doc.data().marketSegment !== 'undefined'){
            marketSegment = doc.data().marketSegment
          }
          let name = ''
          if(typeof doc.data().name !== 'undefined'){
            name = doc.data().name
          }
          let number = ''
          if(typeof doc.data().number !== 'undefined'){
            number = doc.data().number
          }
  
          let oldestTransactionDate = ''
          if(typeof doc.data().oldestTransactionDate !== 'undefined'){
            oldestTransactionDate = doc.data().oldestTransactionDate
          }
  
          let status = ''
          if(typeof doc.data().status !== 'undefined'){
            status = doc.data().status
          }
          let type = ''
          if(typeof doc.data().type !== 'undefined'){
            type = doc.data().type
          }
  
          let hasPositions = false
  
          if(typeof doc.data().position !== 'undefined'){
            let positionArray = doc.data().position
            if(positionArray.length >= 2){
              hasPositions = true
            }
          }
  
          let icon = 'nil'
          let logo = 'nil'
          let tile = 'nil'
         
  
  
          if(typeof doc.data().icon !== 'undefined'){
            icon = doc.data().icon
          }
  
          if(typeof doc.data().logo !== 'undefined'){
           logo = doc.data().logo
          }
  
          if(typeof doc.data().tile !== 'undefined'){
            tile = doc.data().tile
          }
  
          if(balance !== 0){
            localArray.push({docId: doc.id, accountNickname: accountNickname, accountNumberDisplay: accountNumberDisplay, aggregationAttemptDate: aggregationAttemptDate, aggregationStatusCode: aggregationStatusCode,
              aggregationSuccessDate: aggregationSuccessDate, balance: balance, balanceDate: balanceDate, createdDate: createdDate, currency:currency, detailCurrentBalance: detailCurrentBalance,
            detailLastPaymentAmount: detailLastPaymentAmount, detailLastPaymentDate: detailLastPaymentDate, detailPaymentDueDate: detailPaymentDueDate, detailPaymentMinAmount: detailPaymentMinAmount,
            detailStatementCloseBalance: detailStatementCloseBalance, displayPosition: displayPosition, accountId: accountId, institutionId: institutionId, institutionLoginId: institutionLoginId, isFinicity: true, 
          lastTransactionDate: lastTransactionDate, lastUpdatedDate: lastUpdatedDate, marketSegment: marketSegment, name: name, number:number, oldestTransactionDate: oldestTransactionDate, status:status,
        type: type, hasPositions: hasPositions, icon: icon, logo: logo, tile:tile})
          }
       
  
  
  
        }
        else {
          if(doc.data().balance !== 0){
            let accountNickname1 = doc.data().name
            if(typeof doc.data().accountNickname1 !== 'undefined'){
              accountNickname1 = doc.data().accountNickname
            }
            localArray.push({docId: doc.id, balance: doc.data().balance, isFinicity: false,accountNickname:accountNickname1,  name: doc.data().name, institutionId: doc.data().institutionId})
          }
        
        }
       
      })
      return localArray
    }).catch(e => {
      return []
    })


  return accounts;
  }catch(e){
    uploadError(e.message,'AccountMethods.js, getInvestmentAccountsFromFireStore')
  }

}

export async function generateFixCustomUrl(finCustomerId,institutionLoginId){

  try{
    let functions = getFunctions();
    let retrieveUrl = httpsCallable(functions,'generateFixCustomUrl');
    let urlResult = await retrieveUrl({customerId:finCustomerId, institutionLoginId: institutionLoginId}).then(function(result){
        return result;
    }).catch(function(error){
        console.log('error generating fin url');
        return false;
    })

    return urlResult;
  
  }catch(e){
    uploadError(e.message,'AccountMethods.js, generateFixCustomurl')
  }
}

export async function getFastFinAccountsHelper(finCustomerId){
  try{
      const functions = getFunctions();
      let retrieveBalances = httpsCallable(functions,'getFastFinAccounts');  
      let finAccountsArray = await retrieveBalances({customerId: finCustomerId}).then(function(result) {
        return result.data.accounts;
      }).catch(function(error){
        return [];
      })

  return finAccountsArray;
  
  }catch(e){
  uploadError(e.message,"function is getAccounts, AccountMethods.js",)
  }
  }

export async function handleAddInvestmentLineItem(randomId,lineItemValue,type,lineItemName,institutionId){

  try{

    const db = getFirestore();
    let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'Accounts',randomId)
    await setDoc(docRef,{docId: randomId, balance: parseFloat(lineItemValue), isFinicity: false, type: type, name: lineItemName, institutionId: institutionId}).then(() => {
      return true;
    }).catch(() => {
      return false;
    })
  }catch(e){
    uploadError(e.message,'AccountMethods.js, handleAddInvestmentLineItem')
  }
}

export async function handleAddInvestmentGroup(randomId,balance,officialName,institutionId){
  try{
    const db = getFirestore();
    const uid = getAuth().currentUser.uid;
    let docRef = doc(db,'EuklesUsersData',uid,'Accounts',randomId)
    await setDoc(docRef,{docId: randomId, balance: parseFloat(balance), name:officialName, isFinicity:false, institutionId: institutionId, })
  }catch(e){
    uploadError(e.message,'AccountMethods.js, handleAddInvestmentGroup')
  }
}

  export function handleCalculateTotals(localAssetsArray, localLiabilitiesArray){

    let assetTotal = 0.00
    let liabilityTotal = 0.00
 
  
    for(let i = 0; i < localAssetsArray.length; i++){
      if(typeof localAssetsArray[i].accountsArray !== 'undefined'){
        let accountsArray = localAssetsArray[i].accountsArray
        for(let b = 0; b < accountsArray.length; b++){
          assetTotal += accountsArray[b].balance
        }
      }
    }
  
    for(let i = 0; i < localLiabilitiesArray.length; i++){
      if(typeof localLiabilitiesArray[i].accountsArray !== 'undefined'){
        let accountsArray = localLiabilitiesArray[i].accountsArray
        for(let b = 0; b < accountsArray.length; b++){
          liabilityTotal += accountsArray[b].balance
        }
      }
    }
  
 
    let netWorth = parseFloat(assetTotal) + parseFloat(liabilityTotal)

    return {assetTotal, liabilityTotal,netWorth}
  
}

export async function handleDeleteInvestmenGroup(accounts){

  try{
    const db = getFirestore();
    const userUid = getAuth().currentUser.uid;
   
    for(let i = 0; i < accounts.length; i++){
      let docRef = doc(db,'EuklesUsersData',userUid,'Accounts',accounts[i].docId)
      await deleteDoc(docRef)
   
    }
  }catch(e){
    uploadError(e.message,'AccountAndFinMethods.js, handleDeleteInvestmentGroup')
  }

}

export async function handleDeleteInvestmentLineItem(docId){
  try{
    const db = getFirestore();
    let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'Accounts',docId)
    await deleteDoc(docRef)
  }catch(e){ 
    uploadError(e.message,'handleDeleteInvestmentLineItem, AccountMethods.js')
  }
 
}

export async function handleUpdateInvestmentLineItem(docId,officialName,balance){
  try{
    const db = getFirestore();
    let docRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'Accounts',docId)
    await updateDoc(docRef, {name: officialName,balance: parseFloat(balance)})
  }catch(e){
    uploadError(e.message,'AccountMethods.js, handleUpdateInvestmentLineItem')
  }

}


export async function refreshFinAccountsHelper(finCustomerId){
  try{
    const functions = getFunctions();
    let retrieveBalances = httpsCallable(functions,'refreshFinAccounts');  
    let finAccountsArray = await retrieveBalances({customerId: finCustomerId}).then(function(result) {
      return result.data.accounts;
    }).catch(function(error){
      return [];
    })


return finAccountsArray;

}catch(e){
uploadError(e.message,"function is getAccounts, AccountMethods.js",)
}
}

export async function retrieveFinicityUrl(finCustomerId){
  const functions = getFunctions();
  let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl');
  let urlResult = await retrieveUrl({customerId:finCustomerId}).then(function(result){
      return result;
  }).catch(function(error){
   
      return false;
  })
  return urlResult
}

  export async function uploadAccountsHelper(finAccountsArray){
    try{
      const db = getFirestore();
      const userUid = getAuth().currentUser.uid;
      let accountsRef = collection(db,'EuklesUsersData',userUid,'Accounts')
      let q = query(accountsRef,where("isCashFlowAccount","==",true))
      let cashFlowAccountId = await getDocs(q).then(querySnapshot => {
       let id = ''
        querySnapshot.forEach(doc => { 
          id = doc.id
        })
        return id
      }).catch(e => {
        return false
      })
  
      if(finAccountsArray === false || finAccountsArray.length === 0){
        //error
      }
      else {
        
        for(let i = 0; i < finAccountsArray.length; i++){
            if(finAccountsArray[i].id !== cashFlowAccountId){
                let myObject = {...finAccountsArray[i], isFinicity:true}
                let accountsDoc = doc(db,'EuklesUsersData',userUid,'Accounts',String(finAccountsArray[i].id))
              
                await setDoc(accountsDoc,myObject)
  
            }
            else {
                let myObjectAlt = {...finAccountsArray[i], isFinicity:true, isCashFlowAccount:true}
                let accountsDocRef = doc(db,'EuklesUsersData',userUid,'Accounts',String(finAccountsArray[i].id))
                await setDoc(accountsDocRef,myObjectAlt)
        
            }
         
      }
    }
    }catch(e){
      uploadError(e.message,'AccountMethods.js, uploadAccountsHelper.js')
    }
    
}

export async function uploadBranding(accountId,logo){

  try{
    const uid = getAuth().currentUser.uid;
    const db = getFirestore();
  
   let accountsRef = doc(db,'EuklesUsersData',uid,'Logos',String(accountId))
   await setDoc(accountsRef,{logo:logo})
     

  }catch(e){
    //uploadError(e.message,'AccountMethods.js,uploadBranding')
  }
  
    
  }


  export async function retrieveBranding(institutionId){

    try{

      const functions = getFunctions();
  

      let logo = 'nil'
      // let icon = 'nil'
      // let tile = 'nil'
  
      let getBranding = httpsCallable(functions,'retrieveInstitutionBranding')
      let brandingObj = await getBranding({institutionId: institutionId}).then((res) => {
           return res.data.branding;
       }).catch((error) => {
        
           return false;
       })
    
       if(brandingObj !== false){
        if(typeof brandingObj.logo !== 'undefined'){
          logo = brandingObj.logo
        }
    
        // if(typeof brandingObj.icon !== 'undefined'){
        //  icon = brandingObj.icon
        // }
    
        // if(typeof brandingObj.tile !== 'undefined'){
        //   tile = brandingObj.tile
        // }
  
  
    //  let accountsRef = doc(db,'EuklesUsersData',uid,'Accounts',String(account.id))
        
    //  await updateDoc(accountsRef,{logo:logo,icon:icon,tile:tile})
     //return {logo: logo, icon:icon,tile:tile}
     return logo;
       }
  
    }catch(e){
      uploadError(e.message,'AccountMethods.js,uploadBranding')
    }
    
      
    }




