import  BudgetEvenLogo from '../pages/landingpage/landingimages/budgeteven.svg'
import { Grid } from '@mui/material';
import React from 'react'
import {useRive,Layout,Fit,Alignment} from '@rive-app/react-canvas';
import RiveFileMain from './mainloadingfile.riv'



export default function RiveUpdatingBudget() {

    const { RiveComponent } = useRive({
        src: RiveFileMain,
        stateMachines: "State Machine 1",
        autoplay: true,
        artboard:'mainloading',
      
        layout: new Layout({
            fit:Fit.Contain,
            alignment: Alignment.Center
        })
        
      });


      // useEffect(() => {
      
      //   if(rive !== null){
      //     rive.setTextRunValue('MainText',mainText)
      //   }

      // },[rive,mainText])




    return (    <div style={{display:'flex',flexDirection:'column', flex:1,height:'100%',width:'100%', justifyContent:'center', alignContent:'center',backgroundColor:'white'}}>
        <Grid container direction='row' justifyContent='center' mt={5}>
         <Grid item>
         <img style={{alignSelf:'center',width:300,height:'auto'}} src={BudgetEvenLogo}  alt="Logo" />
         </Grid>
       </Grid>
       <div style={{height:500,width:500,alignSelf:'center'}}><RiveComponent /></div>
 </div>


     )
  


}
