import DashboardLayout from 'Layouts/DashboardLayout.jsx';
import LoadingScreen from 'pages/loading/LoadingScreen.js';
import { lazy, Suspense } from 'react';
import OnBoardingWelcome from 'pages/onboarding/FirstTwoSteps/OnBoardingWelcome.js';
import GetStarted from 'pages/onboarding/FirstTwoSteps/GetStarted.js';



import AutoTransactionScanningUpdated from 'pages/mainpages/budgethome/autoscanning/AutoTransactionScanningUpdated.js';

import MainLoading from 'pages/mainpages/MainLoading.js';
import UpdateManageRules from 'pages/mainpages/transactions/UpdatedManageRules.js';
import EditSingleRule from 'pages/mainpages/transactions/EditSingleRule.js';

import BudgetRecapAfterScan from 'pages/mainpages/budgethome/autoscanning/BudgetRecapAfterScan.js';
import NoBudgetPagePremium from 'pages/mainpages/budgethome/NoBudgetPremium.js';
import UpdatedAccounts from 'pages/mainpages/accountshome/UpdatedAccounts.js';
import UpdatedViewAccountTransactions from 'pages/mainpages/accountshome/UpdatedViewAccountTransactions.js';

import EditRuleForce from 'pages/mainpages/transactions/EditRuleForce.js';

import DeleteAccount from 'pages/deleteaccount/DeleteAccount.js';

import UpdatedPricingPage from 'pages/pricingpage/UpdatedPricingPage.js';
import UpdatedCheckOutInterior from 'pages/pricingpage/UpdatedCheckOutInterior.js';


import OnBoardCheckout from 'pages/onboarding/PricingAndCheckOut/OnBoardCheckout.js';
import FinicityAlreadyAutoWithBudget from 'pages/AlreadyAutoWithBudgetConClick/FinicityAlreadyAutoWithBudget.js';
import FinicityAlreadyAuthWithBudgetLoading from 'pages/AlreadyAutoWithBudgetConClick/FinicityAlreadyAutoWithBudgetLoading.js';




import HowToStartABudget from 'pages/blogpage/blogarticles/HowToStartABudget.js';
import HowToSaveMoney from 'pages/blogpage/blogarticles/HowToSaveMoney.js';
import BudgetingAnnualExpenses from 'pages/blogpage/blogarticles/BudgetingAnnualExpenses.js';
import ShouldYouUseCreditCards from 'pages/blogpage/blogarticles/ShouldYouUseCreditCards.js';
import { useRoutes } from 'react-router';
import ProtectedRoute from 'ProtectedRoute.js';
import OpportunityCost from 'pages/blogpage/blogarticles/OpportunityCost.js';
import EmergencyBudget from 'pages/blogpage/blogarticles/EmergencyBudget.js';
import AvoidSpendingEuphoria from 'pages/blogpage/blogarticles/AvoidSpendingEuphoria.js';
import CompoundInterest from 'pages/blogpage/blogarticles/CompoundInterest.js';

const Loadable = Component => props => <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>; // Landing page



const AnalysisHomePage = Loadable(lazy(() => import('./pages/mainpages/analysishome/AnalysisHomePage.js')))
const AccountSettings = Loadable(lazy(() => import('./pages/accountsettings/AccountSettings')));
const Blog = Loadable(lazy (() => import('./pages/blogpage/BlogPage.jsx')))
const CashFlowLarge = Loadable(lazy(() => import('./pages/mainpages/cashflowsection/CashFlowUpdate.jsx')))

const Error = Loadable(lazy(() => import('./pages/errorpage/404')));
const ForgetPassword = Loadable(lazy(() => import('./pages/loginandregistration/ForgetPassword.jsx')));

const InvestmentHomePage = Loadable(lazy(() => import('./pages/mainpages/investmentshome/InvestmentHomePage.js')))
const LandingPage = Loadable(lazy(() => import('./pages/landingpage/LandingPage')));
const LoginPage = Loadable(lazy(() => import('./pages/loginandregistration/LoginPage.js')))
const ManageLineItemPage = Loadable(lazy(() => import('./pages/mainpages/cashflowsection/ManageLineItemPage.js')))
const OutsideTermsOfService =  Loadable(lazy(() => import('./pages/termsandprivacy/TermsOfService.js')))

const PrivacyPage = Loadable(lazy(() => import('./pages/termsandprivacy/Privacy')));

const Register = Loadable(lazy(() => import('./pages/loginandregistration/Register.jsx')));



const BudgetHomePageAlt = Loadable(lazy(() => import('./pages/mainpages/budgethome/BudgetHomePageAlt.js')))


const AppRoutes = () => {


  const routes = useRoutes([{
    path: '/',
  
       element: <LandingPage />
   
  }, 
 
  {
    path: 'login',
    element: <LoginPage />
   
  }, 
  {
    path: 'register',
    element: <Register />
        
  }, 
  {
    path: 'forget-password',
    element: <ForgetPassword />
     
  }, 
  
  {
    path:'privacypolicy',
    element: <PrivacyPage />
  },
  {
    path:'termsofservice',
    element: <OutsideTermsOfService />
  },
  
  {
    path:'deleteaccount',
    element: <DeleteAccount />
  },
  
  {
    path:'learn',
    element: <Blog />,
  
  },
  
  {
    path:'learn/howtostartabudget',
    element: <HowToStartABudget />
  
  },
  
  {
    path:'learn/howtosavemoney',
    element: <HowToSaveMoney />
  
  },
  
  {
    path:'learn/budgetingannualexpenses',
    element: <BudgetingAnnualExpenses />
  
  },
  
  {
    path:'learn/usingcreditcards',
    element: <ShouldYouUseCreditCards/>
  
  },

  {
    path:'learn/opportunitycost',
    element: <OpportunityCost />
  
  },

  {
    path:'learn/compoundinterest',
    element: <CompoundInterest />
  
  },

  {
    path:'learn/emergencybudget',
    element: <EmergencyBudget />
  
  },
  {
    path:'learn/avoidspendingeuphoria',
    element: <AvoidSpendingEuphoria />
  
  },


  {
    path:'mainloading',
  element: <ProtectedRoute><MainLoading /></ProtectedRoute>
    
  },
  {
    path:'welcome',
  element: <ProtectedRoute><OnBoardingWelcome /></ProtectedRoute>
  },
  
  {
    path: 'getstarted',
    element: <ProtectedRoute><GetStarted /></ProtectedRoute>
   
  }, 
  
  {
    path: 'onboardcheckout',
    element: <ProtectedRoute><OnBoardCheckout /></ProtectedRoute>
   
  }, 
   
  
  
  
  {
    path:'autotransactionscanning',
    element: <ProtectedRoute><AutoTransactionScanningUpdated /></ProtectedRoute>
  },
  {
    path:'budgetrecapafterscan',
    element: <ProtectedRoute><BudgetRecapAfterScan /></ProtectedRoute>
  },
  
  {
    path:'nobudgetpremium',
    element: <ProtectedRoute><NoBudgetPagePremium /></ProtectedRoute>
  },
  
  
  {
    path: 'dashboard',
    element: <ProtectedRoute><DashboardLayout /></ProtectedRoute>,
  
    children: [

    {path: 'budgethome',
    element: <BudgetHomePageAlt />
  }, 
  {
    path: 'managerules',
    element: <UpdateManageRules />
  },
  
  
  
  {
    path: 'editrule',
    element: <EditSingleRule />
  },
  {
    path: 'ruleedit',
    element: <EditRuleForce />
  },
  
  
    
    
    {
      path: 'balancesheet',
    
       element: <InvestmentHomePage />
    }, 
  
    {
      path:'cashflowlineitems',
      element: <ManageLineItemPage />
      
    },
  
  
    {
      path: 'transactions',
      element: <AnalysisHomePage />
    },
    {
      path:'account-settings',
      element: <AccountSettings />
    },
  

  
  
    {
      path:'accountshome',
      element: <UpdatedAccounts />,
      
    },
  
    {path:'accounttransactions',
        element: <UpdatedViewAccountTransactions />
    },
    
    {
      path:'cashflow',
      element: <CashFlowLarge />
    },
    {
      path:'pricing',
      element: <UpdatedPricingPage />
    },
    {
      path:'checkout',
      element: <UpdatedCheckOutInterior />
    },

  
    {
      path:'finicityaawb',
      element: <FinicityAlreadyAutoWithBudget />
    },
  
    {
      path:'finicityaawbl',
      element: <FinicityAlreadyAuthWithBudgetLoading />
    },
  
  
  ]
  }, {
    path: '*',
    element: <Error />
  }])


  return routes;
}

export default AppRoutes;




// export const routes = [{
//   path: '/',

//      element: <LandingPage />
 

// }, 
// {
//   path:'mainloading',
// element: <MainLoading />
// },
// {
//   path:'welcome',
// element: <OnBoardingWelcome />
// },

// {
//   path: 'getstarted',
//   element: <GetStarted />
 
// }, 

// {
//   path: 'onboardcheckout',
//   element: <OnBoardCheckout />
 
// }, 
 

// {
//   path: 'budgetrecap',
//   element: <BudgetRecap />
 
// }, 



// {
//   path:'autotransactionscanning',
//   element: <AutoTransactionScanningUpdated />
// },
// {
//   path:'budgetrecapafterscan',
//   element: <BudgetRecapAfterScan />
// },

// {
//   path:'nobudgetpremium',
//   element: <NoBudgetPagePremium />
// },





 



// {
//   path: 'login',
//   element: <LoginPage />
 
// }, 
// {
//   path: 'register',
//   element: <Register />
      
// }, {
//   path: 'forget-password',
//   element: <ForgetPassword />
   
// }, 

// {
//   path:'privacypolicy',
//   element: <PrivacyPage />
// },
// {
//   path:'termsofservice',
//   element: <OutsideTermsOfService />
// },

// {
//   path:'deleteaccount',
//   element: <DeleteAccount />
// },

// {
//   path:'learn',
//   element: <Blog />,

// },

// {
//   path:'learn/howtostartabudget',
//   element: <HowToStartABudget />

// },

// {
//   path:'learn/howtosavemoney',
//   element: <HowToSaveMoney />

// },

// {
//   path:'learn/budgetingannualexpenses',
//   element: <BudgetingAnnualExpenses />

// },

// {
//   path:'learn/usingcreditcards',
//   element: <ShouldYouUseCreditCards/>

// },



// {
//   path: 'dashboard',
//   element: <DashboardLayout />,

//   children: [
//   //   {
//   //   path: 'budgethome',
//   //   element: <DashboardSaaS />
//   // }, 
//   {path: 'budgethome',
//   element: <BudgetHomePageAlt />
// }, 
// {
//   path: 'managerules',
//   element: <UpdateManageRules />
// },



// {
//   path: 'editrule',
//   element: <EditSingleRule />
// },
// {
//   path: 'ruleedit',
//   element: <EditRuleForce />
// },


  
  
//   {
//     path: 'balancesheet',
  
//      element: <InvestmentHomePage />
//   }, 

//   {
//     path:'cashflowlineitems',
//     element: <ManageLineItemPage />
    
//   },


//   {
//     path: 'transactions',
//     // element: <DashboardProjectManagement />
//     element: <AnalysisHomePage />
//   },
//   {
//     path:'account-settings',
//     element: <AccountSettings />
//   },

//   {
//     path:'upgradefsatap',
//     element: <UpgradeFromSemiAutoToAutoPage />
//   },

//   {
//     path:'upgradefmtap',
//     element: <UpgradeFromManualToAutoPage />
//   },



//   {
//     path:'accountshome',
//     element: <UpdatedAccounts />,
    
//   },

//   {path:'accounttransactions',
//       element: <UpdatedViewAccountTransactions />
//   },
  
//   {
//     path:'cashflow',
//     element: <CashFlowLarge />
//   },
//   {
//     path:'pricing',
//     // element: <PricingsPage />
//     element: <UpdatedPricingPage />
//   },
//   {
//     path:'checkout',
//     // element: <CheckOutPage />
//     element: <UpdatedCheckOutInterior />
//   },
//   {
//     path:'updatesubscription',
//     element: <UpdateSubscription />
//   },


//   {
//     path:'finicityaawb',
//     element: <FinicityAlreadyAutoWithBudget />
//   },

//   {
//     path:'finicityaawbl',
//     element: <FinicityAlreadyAuthWithBudgetLoading />
//   },


// ]
// }, {
//   path: '*',
//   element: <Error />
// }];


