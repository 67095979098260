

import { AltDataContext } from 'contexts/AltDataContext';
import  BudgetEvenLogo from '../../pages/landingpage/landingimages/budgeteven.svg'
import { getCurrentDayDigit, getCurrentMonthAndYearString} from 'utils/Helpers';
import OnBoardingMenu from 'pages/onboarding/MenuPopover/OnBoardingMenu';
import React,{useEffect,useContext,useState} from 'react'
import RiveMainLoading from 'rivefiles/RiveMainLoading';
import { useNavigate } from 'react-router';
import {getUserProfile } from 'utils/UserMethods';
import { uploadError } from 'utils/FirebaseAnalyticsMethods';
import { getSavingsGoal,getBudgetMonths,getBudgetPodsByMonth, getGenericBudgetMonths } from 'utils/BudgetMethods';
import { getFastFinAccountsHelper,uploadAccountsHelper } from 'utils/AccountMethods';
import YouNeedABudgetDialog from './budgethome/modals/YouNeedABudgetDialog';
import RiveScanning from 'rivefiles/RiveScanning';
import { Grid } from '@mui/material';
import RiveUpdatingBudget from 'rivefiles/RiveUpdatingBudget';
import RiveRunningTrackingRules from 'rivefiles/RiveRunningTrackingRules';


export default function MainLoading () {

  const navigate = useNavigate();
  const {dispatch} = useContext(AltDataContext)
  const [emptyBudgetMonthQueried,setEmptyBudgetMonthQueried] = useState('')
  const [showNoBudget,setShowNoBudget] = useState(false)



  useEffect(() => {
    document.body.style.backgroundColor = 'white'
  },[])
  

  useEffect(() => {
    updatedCheckOnBoardingStatus()
  },[])



  async function checkIfPodsExistForCurrentMonth(){
    let budgetExists = false;
    let budgetMonthsArray = await getBudgetMonths();
  
    dispatch({type:'UPDATE_BUDGET_MONTHS_ARRAY',payload:budgetMonthsArray})
    let currentMonth = getCurrentMonthAndYearString();
    let existingIndex = budgetMonthsArray.findIndex(obj => obj === currentMonth)
    if(existingIndex !== -1){
      budgetExists = true
    }
  return budgetExists
  }

  async function closeNoBudgetDialog(){
    let emptyMonth = getCurrentMonthAndYearString();
    await getBudgetPodsLocal()
    dispatch({type:'UPDATE_BUDGET_MONTH',payload:emptyMonth})
    navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
    setShowNoBudget(false)
  }

  function determineIfScanNeeded(lastScanDigit){

    let scanBasedUponDigit = false
    if(lastScanDigit !== 'nil'){
      let today = getCurrentDayDigit();
     
      if(String(today) !== String(lastScanDigit)){
      scanBasedUponDigit = true
      }
   
    }
    return scanBasedUponDigit
  }

  async function determineRoute(isPremiumUser,lastScanDigit,lastTransactionScanEpoch,finicityId,budgetMethod){
    try{
      if(isPremiumUser){
      
        const accounts = await getFastFinAccountsHelper(finicityId)
        if(accounts.length >= 1){
          await uploadAccountsHelper(accounts);

        }
       //lastScanDigit = 18;
        if(determineIfScanNeeded(lastScanDigit)){
          let podsExist = await checkIfPodsExistForCurrentMonth();
                if(podsExist){
                  navigate('/autotransactionscanning')
                }
                else {
                  let emptyMonth = getCurrentMonthAndYearString();
                  setEmptyBudgetMonthQueried(emptyMonth)
                  setShowNoBudget(true)
                }
        }
        else {
          //check pods exists for given month
          let podsExist = await checkIfPodsExistForCurrentMonth();
                if(podsExist){
                  await getBudgetPodsLocal()
                  dispatch({type:'UPDATE_BUDGET_MONTH',payload:getCurrentMonthAndYearString()})
                  navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
              
                }
                else {
                  //let localMonthsArray = await getBudgetMonths();
                  let emptyMonth = getCurrentMonthAndYearString();
                 setEmptyBudgetMonthQueried(emptyMonth)
                 setShowNoBudget(true)
                }
    
        }
      }
      else {
        //check that pods exists
                let podsExist = await checkIfPodsExistForCurrentMonth();
          
                  if(podsExist){
                    await getBudgetPodsLocal()
                    dispatch({type:'UPDATE_BUDGET_MONTH',payload:getCurrentMonthAndYearString()})
                    navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
                  }
                  else {
                 
                    let emptyMonth = getCurrentMonthAndYearString();
                    setEmptyBudgetMonthQueried(emptyMonth)
                    setShowNoBudget(true)
                  }
      }
    }catch(e){
      uploadError(e.message,'MainLoading, determienRoute')
    }
  
  
  }

  async function getBudgetPodsLocal(){
    let currentMonth = getCurrentMonthAndYearString()
    let podsArray = await getBudgetPodsByMonth(currentMonth)
    dispatch({type:'UPDATE_PODS',payload:podsArray})
    return podsArray;
  }

  async function updatedCheckOnBoardingStatus(){

    let userData = await getUserProfile();
    let genericMonths = await getGenericBudgetMonths();
    try{
      if(typeof userData.startDate !== 'undefined'){
        let existingIndex = genericMonths.findIndex(obj => obj === userData.startDate)
        genericMonths.splice(0,existingIndex)
       
      }
    }catch(e){
      uploadError(e.message,'MainLoading.js, updatedCheckOnBoardingStatus')
    } 

    dispatch({type:'UPDATE_GENERIC_BUDGET_MONTHS_ARRAY',payload:genericMonths})
 
    if(typeof userData === 'undefined' || userData === false){
      userData = await getUserProfile();
    }
    const savingsGoal = await getSavingsGoal();
  
    if(userData.currentStep === 0){
      dispatch({type:'UPDATE_USER_DATA',payload:userData})
      navigate('/welcome')
    }
  
    else if(userData.currentStep === 1){
      dispatch({type:'UPDATE_USER_DATA',payload:userData})
      navigate('/getstarted')
   
    }
    
    else {
      dispatch({type:'UPDATE_USER_DATA',payload:userData})
      dispatch({type:'UPDATE_MONTHLY_SAVINGS_GOAL',payload:savingsGoal})
      determineRoute(userData.isPremiumUser,userData.lastScanDigit,userData.lastTransactionScanEpoch,userData.finicityId,userData.budgetMethod)
    }
  
  }



      if(showNoBudget){
        return(
          <YouNeedABudgetDialog emptyBudgetMonthQueried={emptyBudgetMonthQueried}  showNoBudget={showNoBudget} closeDialog={closeNoBudgetDialog} />
        )

      }
      else {
        return(
      
          <div style={{display:'flex',flexDirection:'column', flex:1,flex:1,height:'100%',width:'100%', justifyContent:'center', alignContent:'center',backgroundColor:'white'}}>
     
              <div style={{position:'absolute',top:15,right:25}}>
              <OnBoardingMenu navigate={navigate} />
            </div>

          <RiveMainLoading />
        </div>
      )
      }
}

